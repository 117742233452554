<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
      <div class="form-group">
        <label for="">الرقم</label>
        <input
          type="number"
          class="form-control text-center"
          v-model="number"
          dir="ltr"
          placeholder="Enter Number... (like 9665444..)"
        />
      </div>
      <button class="btn btn-primary" @click="add()">اضافة الرقم</button>
    </div>
    <div class="card card-body">
      <div class="col-12 table-responsive">
        <table class="table table-bordered table-hover">
          <thead>
            <th>الرقم</th>
            <th>حذف</th>
          </thead>
          <tbody>
            <tr v-for="n in numbers" :key="n.number">
              <td>
                {{ n.number }}
              </td>
              <td>
                <button
                  class="btn btn-danger btn-sm"
                  @click="deletea(n.number)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      numbers: [],
      number: "",
    };
  },
  created() {
    var g = this;
    $.post(api + "/admin/general/no-checks", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.numbers = r.response;
      })
      .catch(function () {
        alert("error server or internet");
      });
  },
  methods: {
    add() {
      var g = this;
      $.post(api + "/admin/general/add-no-check", {
        jwt: this.user.jwt,
        number: g.number,
      })
        .then(function (r) {
          location.reload();
        })
        .catch(function () {
          alert("error server or internet");
        });
    },
    deletea(number) {
      var g = this;
      $.post(api + "/admin/general/delete-no-check", {
        jwt: this.user.jwt,
        number: number,
      })
        .then(function (r) {
          location.reload();
        })
        .catch(function () {
          alert("error server or internet");
        });
    },
  },
};
</script>

<style>
</style>